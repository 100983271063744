<template>
  <Card>
    <template #content v-if="loading || casesRating.length > 0">
      <div class="grid">
        <div class="col-4">
          <h4>{{ $t("ratings.title") }}</h4>
        </div>
        <div class="col-8 text-right">
          <Button
            v-if="!isMobile"
            :label="$t('global.export')"
            @click="exportCSV"
          ></Button>
        </div>
      </div>
      <DataTable
        :value="casesRating"
        :paginator="true"
        :rows="10"
        :loading="loading"
        :filters="filters"
        dataKey="id"
        ref="tableRatings"
        showGridlines
        class="p-datatable-ratings"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 25, 50]"
        :currentPageReportTemplate="$tableString"
        removableSort
        sortField="ratingDate"
        :sortOrder="-1"
      >
        <template #header>
          <div>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                :placeholder="$t('global.search')"
              />
            </span>
          </div>
        </template>
        <template #empty> {{ $t("ratings.noRatingsFound") }} </template>
        <Column
          field="createdBy"
          :header="$t('ratings.clientId')"
          :sortable="true"
        >
          <template #body="{ data }">
            <span class="text-right">{{ data.createdBy }}</span>
          </template>
        </Column>
        <Column
          field="tempRatingDate"
          :header="$t('ratings.ratingDate')"
          :sortable="true"
        >
          <template #body="{ data }">
            {{ data.ratingDate }}
          </template>
        </Column>
        <Column
          field="companyName"
          :header="$t('ratings.companyName')"
          :sortable="true"
        >
          <template #body="{ data }">
            {{ data.companyName }}
          </template>
        </Column>
        <Column field="id" :header="$t('ratings.certId')" :sortable="true">
          <template #body="{ data }">
            {{ data.certifiedId }}
          </template>
        </Column>
        <Column
          field="companyRepresentative"
          :header="$t('ratings.companyRep')"
          :sortable="true"
        >
          <template #body="{ data }">
            {{ data.companyRepresentative }}
          </template>
        </Column>
        <Column
          field="ratingComments"
          :header="$t('ratings.comments')"
          :sortable="true"
        >
          <template #body="{ data }">
            {{ data.ratingComments }}
          </template>
        </Column>
        <Column
          field="rating"
          :header="$t('ratings.rating')"
          :sortable="true"
          class="w-6"
        >
          <template #body="{ data }">
            <Rating v-model="data.rating" :cancel="false" :readonly="true" />
          </template>
        </Column>
      </DataTable>
    </template>
    <template #content v-else-if="!loading">
      <NoTableData :type="'ratings'"></NoTableData>
    </template>
  </Card>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import NoTableData from "./NoTableData.vue";
import { mapGetters } from "vuex";

export default {
  name: "Ratings",
  components: {
    NoTableData,
  },
  data() {
    return {
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      loading: false,
    };
  },
  async mounted() {
    this.loading = true;
    await this.$store.dispatch("cases", {});
    this.loading = false;
    this.$action("ratings-page-view");
  },
  methods: {
    exportCSV() {
      this.$refs.tableRatings.exportCSV();
      this.$action("export-ratings");
    },
  },
  computed: {
    ...mapGetters(["casesRating", "isMobile"]),
  },
};
</script>

<style lang="scss" scoped>
:deep() {
  .p-rating-icon {
    font-size: 2.5rem !important;
  }
  @media screen and (max-width: 769px) {
    .p-rating-icon {
      font-size: 2rem !important;
    }
  }
}
</style>
